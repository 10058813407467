section.job-detail {
    padding: 0;

    @include desktop() {
        padding: 1.5em;
        display: flex;
        flex-direction: row;
    }

    > div.description {
        padding-left: 2em;
        padding-right: 2em;
        background-color: inherit;
        box-shadow: none;

        @include desktop() {
            margin-right: 1.5em;
            order: 1;
            flex: 1;
            background-color: $white;
            box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
        }

        > div.job-heading {

            @include desktop() {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            > h2 {
                font-size: 1.6em;
                font-weight: 500;
                display: none;

                @include desktop() {
                    display: inline-block;
                    order: 1;
                }
            }

            > span.fill {
                order: 2;
                display: none;

                @include desktop() {
                    display: inline-block;
                }
            }

            > div.job-actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                @include desktop() {
                    order: 3;
                }

                > button {
                    border: none;
                    background: transparent;
                    margin: 0 1em 0 0;
                    font-size: 1.1em;
                    color: lighten($base-color, 15%);
                    order: 1;
                    white-space: nowrap;

                    @include desktop() {
                        order: 2;
                        margin: 0 1em;
                    }

                    > i {
                        margin-right: 5px;
                        font-size: 1.3em;
                    }

                    &[name="share"] {
                        padding-left: 0;

                        @include desktop() {
                            display: inline-block;
                        }
                    }
                }

                > div.share-outlets {
                    border-left: 1px solid transparent;
                    padding-right: 20px;
                    order: 2;
                    white-space: nowrap;

                    @include desktop() {
                        order: 1;
                        border-left: none;
                        border-right: 1px solid transparent;
                    }

                    @for $i from 1 through 4 {
                        a:nth-child(#{$i}) {
                            transition-delay: ((#{500-($i*100ms)}));
                        }
                    }

                    > a {
                        font-size: 1.3em;
                        padding: 5px 10px;
                        box-sizing: border-box;
                        display: inline-block;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 100ms ease;
                        transform: translateX(15px);
                    }
                }

                &.share-open {
                    > button[name="share"] {
                        color: lighten($primary, 20%);
                    }

                    > button[name="print"] {
                        display: none;

                        @include desktop() {
                            display: inline-block;
                        }
                    }

                    > div.share-outlets {
                        border-left: 1px solid lighten($primary, 20%);

                        @include desktop() {
                            border-left: none;
                            border-right: 1px solid lighten($primary, 20%);
                        }

                        > a {
                            opacity: 1;
                            visibility: visible;
                            transform: translateX(0);
                        }
                    }
                }
            }
        }

        > div.job-details {
            word-wrap: break-word;
            line-height: 1.5em;
            margin-top: 1em;
        }
    }

    > div.apply {
        margin-bottom: 1.5em;
        padding-top: 2em;

        @include desktop() {
            width: 30%;
            max-width: 450px;
            height: 100%;
            order: 2;
        }
        // Linked In Integration
                $linked-in-blue: #0077B5;
                //Linked In Alert
                div.alert {
                    $info-blue-font: #245C7E;
                    $info-blue-bg: #D0E8F6;

                    color: $info-blue-font;
                    padding: 1em;
                    margin: 1.5em 0 0;
                    border-radius: .5em;
                    margin-bottom: 1.5em;

                    &.info {
                        border: solid thin darken($info-blue-bg, 10%);
                        background-color: $info-blue-bg;
                    }
                }
                //Or Break
                span.break {
                    position: relative;
                    border-bottom: solid lightgray thin;
                    display: block;
                    //height: 2em;
                    margin: 2em 0;
                    > span {
                        position: absolute;
                        top: -.5em;
                        text-align: center;
                        width: 6%;
                        color: grey;
                        left: 46%;
                        background-color: white;
                        display: block;
                    }
                }
                // Linked In Button
                button.btn-li-lg {
                    display: block;
                    width: 99%;
                    border: none;
                    border-radius: .25em;
                    padding: 0;

                    margin: auto;
                    margin-top: 1.5em;

                    overflow: hidden;

                    background: $linked-in-blue;
                    color: white;

                    text-align: left;

                    -webkit-transition: background-color 250ms;
                    -moz-transition: background-color 250ms;
                    -ms-transition: background-color 250ms;
                    -o-transition: background-color 250ms;
                    transition: background-color 250ms;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: darken($linked-in-blue, 5%);
                    }

                    &.done,
                    &.loading {
                        background-color: transparentize($linked-in-blue, .25);
                        color: rgba(255, 255, 255, .75);
                        > i {
                            border-right-color: lighten($linked-in-blue, 10%);
                        }
                    }

                    > i {
                        font-size: 2.5em;
                        padding: .15em .25em;
                        margin: 0 .25em 0 0;
                        display: inline-block;
                        height: 100%;
                        border-right: solid thin darken($linked-in-blue, 7%);
                    }

                    > span {
                        position: relative;
                        top: -.4em;
                        font-size: 1.1em;
                        font-weight: 600;
                        padding: 0 1.5em 0 0;
                    }

                }

        > div.send {
            margin-top: 1em;
            background-color: #F4F4F4;
            display: block;
            box-shadow: rgba(0, 0, 0, 0.14902) 0 1px 0 0;

            > input.email {
                text-align: left;
                width: 73%;
                height: 35px;
                font-size: 19px;
                border: none;
                background-color: #F4F4F4;
                text-indent: 15px;
                padding: 0;
            }

            > a.send {
                display: inline-block;
                width: 25%;
                cursor: pointer;
                border: none;
                background: $secondary;
                padding-top: 8px;
                text-align: center;
                height: 35px;
                color: $white;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 1.3em;

                &:focus {
                    outline: none;
                }

                &:hover {
                    box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
                }
            }
        }

        > .ios-upload-warning {
            text-align: center;
            padding-top: 25px;
            margin-top: 20px;
            margin-bottom: 42px;
            border-top: 1px solid #ddd;
        }

        > .card-date {
            font-size: 0.9em;
        }

        > .card-title-category {
            > .card-title {
                margin-top: 0.7em;
            }

            > .card-category {
                color: lighten($dark-bg, 50%);
                margin-bottom: 1.3em;
            }
        }

        > .card-location, > .card-type, > .card-separator {
            color: lighten($dark-bg, 25%);
            font-weight: 500;
            font-size: 1.1em;
        }

        > button.apply {
            cursor: pointer;
            width: 100%;
            border: none;
            background: $secondary;
            color: $white;
            padding: 0.5em;
            border-radius: 3px;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 1.3em;
            margin-top: 1em;

            &:focus {
                outline: none;
            }

            &:hover {
                box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
            }

            &.disabled {
                background-color: lightgray;
                margin-top: 1em;
            }
        }

        > hr {
            border: none;
            height: 1px;
            background: #E8E8E8;
            margin: 2em -0.5em;
            display: none;

            @include desktop() {
                display: block;
            }
        }

        > div.related-jobs {
            display: none;

            @include desktop() {
                display: block;
            }

            > h5 {
                font-size: 1.2em;
                font-weight: 400;
            }

            > div.related-job {
                padding: 0 1em;
                margin-bottom: 1.5em;

                > a {
                    font-size: 1.2em;
                    font-weight: 500;
                    max-width: 100%;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: bottom;
                    white-space: nowrap;
                }

                > span.separator {
                    font-size: 1.2em;
                }

                > span.category {
                    font-size: 1.1em;
                    color: lighten($dark-bg, 5%);
                    font-weight: 400;
                }

                > span.location {
                    margin-top: 0.2em;
                    display: block;
                    font-size: 1em;
                    color: lighten($dark-bg, 45%);
                    font-weight: 500;
                }
            }
        }

        > a.category-filter {
            font-weight: 700;
            margin-bottom: 1em;
            display: none;

            @include desktop() {
                display: block;
            }

            > i {
                float: right;
            }
        }
    }
}
