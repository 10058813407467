section.job-list {
    padding: 1.5em;

    > h4 {
        color: darken($light-bg, 15%);
        font-size: 1.3em;
        font-weight: 500;
        margin: 0 0 1em;
    }

    > p.load-more-data {
        text-align: center;
        margin: 0;
        font-size: 1.2em;
        cursor: pointer;
    }

    // Parent selector
    .list-view & {
        div.cards {
            display: block;

            > div.card-wrapper {
                display: block;

                > a.card {
                    margin-bottom: 1.5em;
                    overflow: hidden;
                    cursor: pointer;
                    display: block;

                    @include desktop() {
                        display: flex;
                        flex-flow: row wrap;
                        align-items: center;
                    }

                    > i {
                        order: 5;
                        display: none;

                        @include desktop() {
                            display: inline-block;
                        }
                    }

                    > .card-date {
                        display: none;

                        @include desktop() {
                            display: block;
                            flex: 1;
                            color: lighten($dark-bg, 40%);
                            font-weight: 500;
                            font-size: 1.1em;
                            order: 4;
                            text-align: center;
                        }
                    }

                    > .card-title-category {
                        display: block;
                        width: 100%;
                        margin-bottom: 0.25em;

                        @include desktop() {
                            width: 40%;
                            margin-bottom: 0;
                        }

                        > .card-title {
                            margin: 0 0 0.1em;
                        }

                        > .card-category {
                            font-size: 1.2em;
                            margin: 0;
                        }
                    }

                    > .card-location, > .card-type {
                        flex: 1;
                        text-align: center;
                    }

                    > .card-separator {
                        @include desktop() {
                            display: none;
                        }
                    }

                    > .card-description {
                        order: 6;
                        width: 100%;
                        margin-top: 1em;
                        color: lighten($dark-bg, 20%);
                        font-weight: 500;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        display: none;

                        @include desktop() {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    // Parent selector
    .grid-view & {
        div.cards {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;

            margin-left: -0.75em;
            margin-right: -0.75em;

            div.card-wrapper {
                width: 100%;
                padding-bottom: 1.5em;

                @include tablet() {
                    width: 50%;

                    padding-right: 0.75em;
                    padding-left: 0.75em;
                }

                @include large-desktop() {
                    width: 33%;
                }

                > a.card {
                    width: 100%;
                    background: $white;
                    padding: 1em;
                    overflow: hidden;
                    cursor: pointer;
                    display: block;
                    min-height: 365px;

                    > i {
                        display: none;
                    }

                    > .card-description {
                        margin-top: 1em;
                        color: lighten($dark-bg, 20%);
                        line-height: 1.5em;
                        font-weight: 500;
                        display: block;
                        word-break: break-all;

                        text-overflow: ellipsis;
                        height: 16em;
                        overflow: hidden;
                        position: relative;

                        &:after {
                            content: "";
                            text-align: right;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 70%;
                            height: 1.2em;
                            background: linear-gradient(to right, rgba(255, 255, 255, 0), $white 100%);
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}